<template>
	<div>
		<el-form :model="ruleForm" :inline="true">
			<el-form-item label="蓝牙名称">
				<el-input v-model="ruleForm.bluetoothName" placeholder="蓝牙名称"></el-input>
			</el-form-item>
			<el-form-item label="设备名称">
				<el-input v-model="ruleForm.serialNumber" placeholder="设备名称"></el-input>
			</el-form-item>
			<!-- 选择时间模块 -->
			<!-- <el-form-item>
				<div class="block">
					<span class="demonstration" style="margin-right: 10px">时间</span>
					<el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
						value-format="yyyy-MM-dd HH:mm:ss" @change="changeTime">
					</el-date-picker>
				</div>
			</el-form-item> -->
			<el-form-item>
				<el-button @click="getInfo" type="primary">搜索</el-button>
			</el-form-item>
			<el-form-item>
				<el-button @click="addDevice" type="primary">添加新设备</el-button>
			</el-form-item>
			<el-form-item>
				<!-- <el-button @click="addall" type="primary">批量添加</el-button> -->
				<el-upload class="upload-demo" :show-file-list="false" ref="upload" accept=".xlsx"
					:action="xxUrl+'/test/import'" :headers="{'token': token}" :on-preview="handlePreview"
					:on-remove="handleRemove" :limit="1" :on-success="onsuccess" :on-error="onerror">
					<el-button slot="trigger" type="primary">批量添加设备</el-button>
					<a href="http://charge.cheersofa.com//file/2021/06/03/导入设备模板.xlsx"
						style="margin-left: 20px; color: red; text-decoration: none;">下载模板</a>
					<!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器 -->
					</el-button>
					<!-- <div slot="tip" class="el-upload__tip">只能上传.xls文件，且不超过500kb</div> -->
				</el-upload>
			</el-form-item>

			<!-- 批量添加 -->
			<!-- <el-dialog title="上传" :visible.sync="add1" width="30%"> -->
			<!-- <el-upload class="upload"  action="https://charge.cheersofa.com/app/save" :multiple="false"
			  	accept=".xls"
			  	:on-change="importExcel" :limit="1"
			  	:on-success="onsuccess"
			  	:on-error="onerror"
			  	:file-list="daor"
			  	:auto-upload="false">
			  	<el-button type="primary">选择文件</el-button>
				<el-button style="margin-left: 10px;"  type="success" @click="submitUpload">上传到服务器</el-button>
			  </el-upload> -->

			<!-- <span slot="footer" class="dialog-footer">
					<el-button @click="addall1">取 消</el-button>
					<el-button type="primary" @click="addall2">上传</el-button>
				</span>
			</el-dialog>
 -->

			<!-- <el-form-item>
				<el-button @click="H1" type="primary">cs</el-button>
			</el-form-item> -->
		</el-form>
		<el-table max-height="550px" :data="tableData" stripe style="width: 100%;min-height: 550px">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<!-- 设备二级菜单 -->
					<el-form label-position="left" inline class="table-expand">
						<!-- <el-form-item label="二维码">
							<img style="width: 50px;height: 50px;" v-if="!scope.row.qrCode"
								src="../../../static/touxiang.png">
							<img style="width: 50px;height: 50px;" v-if="scope.row.qrCode" :src="scope.row.qrCode" />
						</el-form-item> -->
						<!-- <el-form-item label="设备安装地点"><span>{{ scope.row.address }}</span></el-form-item> -->
						<!-- <el-form-item label="设备id"><span>{{ scope.row.id }}</span></el-form-item> -->
						<!-- <el-form-item label="安装时间"><span>{{ scope.row.installationTime }}</span></el-form-item> -->
						<!-- <el-form-item label="设备名称"><span>{{ scope.row.name }}</span></el-form-item> -->
						<el-form-item label="设备名称"><span>{{ scope.row.serialNumber }}</span></el-form-item>
						<el-form-item label="蓝牙名称"><span>{{ scope.row.bluetoothName}}</span></el-form-item>
						<!-- 3.26新增 -->
						<el-form-item label="MAC地址"><span>{{ scope.row.deviceId}}</span></el-form-item>
						<!-- <el-form-item label="deviceId-IOS"><span>{{ scope.row.deviceIosId}}</span></el-form-item> -->
						<!-- <el-form-item label="蓝牙设备id"><span>{{ scope.row.deviceId }}</span></el-form-item> -->
						<el-form-item label="serviceId"><span>{{ scope.row.serviceId }}</span></el-form-item>
						<el-form-item label="characteristicId"><span>{{ scope.row.characteristicId }}</span>
						</el-form-item>
						<!--  -->
					</el-form>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="name" label="设备名称"></el-table-column> -->
			<!-- <el-table-column label="二维码">
				<template slot-scope="scope">
					<img style="width: 50px;height: 50px;" v-if="!scope.row.qrCode" src="../../../static/touxiang.png">
					如果没有图片就选取上面的默认图片
					<el-image style="width: 50px; height: 50px" v-if="scope.row.qrCode" :src="scope.row.qrCode"
						:preview-src-list="[scope.row.qrCode]"></el-image>
				</template>
			</el-table-column> -->
			<el-table-column prop="serialNumber" label="设备名称"></el-table-column>
			<el-table-column prop="bluetoothName" label="蓝牙名称"></el-table-column>
			<!-- 3.26新增 -->
			<el-table-column prop="deviceId" label="MAC地址"></el-table-column>
			<!-- <el-table-column prop="deviceIosId" label="deviceId-IOS"></el-table-column> -->
			<el-table-column prop="serviceId" label="serviceId"></el-table-column>
			<el-table-column prop="characteristicId" label="characteristicId"></el-table-column>
			<!--  -->
			<!-- <el-table-column prop="address" label="设备安装地点"></el-table-column> -->
			<!-- <el-table-column prop="installationTime" label="安装时间"></el-table-column> -->
			<el-table-column label="操作" width="100">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row)" type="text" style="color: #409eff" size="small">修改</el-button>
					<el-button v-if="User == 'true'" @click="del(scope.row)" type="text" style="color: red;"
						size="small">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block" style="display: flex;justify-content: flex-start;margin-top: 20px">

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
				:page-sizes="[10, 20, 50, 100]" :page-size="ruleForm.size"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>

		<!--  添加新设备对话框  -->
		<el-dialog :visible.sync="dialogVisible" width="900px" center>
			<div v-if="dialogbtn == 1" style="font-size: 24px;justify-content: center" slot="title">添加设备</div>
			<div v-if="dialogbtn == 2" style="font-size: 24px;justify-content: center" slot="title">修改信息</div>
			<el-form :rules="rules" ref="addRuleForm" class="rrForm" :model="addRuleForm" label-width="140px">
				<!--   日期     -->
				<!-- <el-form-item label="时间">
					<el-date-picker v-model="addRuleForm.installationTime" placeholder="选择日期" type="date"
						value-format="yyyy-MM-dd" @change="changeTime2">
					</el-date-picker>
				</el-form-item> -->
				<el-form-item label="设备名称" prop="serialNumber">
					<el-input style="width: 710px" v-model="addRuleForm.serialNumber" placeholder="请输入设备名称"></el-input>
				</el-form-item>
				<el-form-item label="蓝牙名称" prop="bluetoothName">
					<el-input style="width: 710px" v-model="addRuleForm.bluetoothName" placeholder="请输入蓝牙名称"></el-input>
				</el-form-item>
				<!-- <el-form-item v-if="false" label="地址">
					<address-select @allAddr="allAddr" :block="`block`" :value="`value`"></address-select>
				</el-form-item> -->
				<!-- 3.26添加MAC地址 -->
				<el-form-item label="MAC地址" prop="deviceId">
					<el-input style="width: 710px" v-model="addRuleForm.deviceId" placeholder="请输入设备MAC地址"></el-input>
				</el-form-item>
				<!-- <el-form-item label="deviceId-IOS" prop="deviceId">
					<el-input style="width: 710px" v-model="addRuleForm.deviceIosId" placeholder="请输入设备MAC地址"></el-input>
				</el-form-item> -->
				<!--  -->

				<!-- 4.2 -->
				<!-- <el-form-item label="蓝牙设备id">
					<el-input style="width: 710px" v-model="addRuleForm.deviceId" placeholder="请输入设备蓝牙设备id"></el-input>
				</el-form-item> -->

				<!-- <el-form-item label="serviceId" prop="serviceId">
					<el-input style="width: 710px" v-model="addRuleForm.serviceId" placeholder="请输入设备uuid"></el-input>
				</el-form-item>

				<el-form-item label="characteristicId" prop="characteristicId">
					<el-input style="width: 710px" v-model="addRuleForm.characteristicId" placeholder="请输入设备特征值">
					</el-input>
				</el-form-item> -->
				<!--  -->
				<!-- <el-form-item label="地址">
					<el-input style="width: 710px" v-model="addRuleForm.address" placeholder="请输入地址"></el-input>
				</el-form-item> -->
			</el-form>
			<div style="display: flex;justify-content: center;margin-top: 0px">
				<el-button type="primary" v-if="dialogbtn == 1" @click="rrFormSubmit('addRuleForm')">提交</el-button>
				<el-button type="primary" v-if="dialogbtn == 2" @click="rrFormEdit">修改</el-button>
				<el-button type="default" @click="rrFormClear">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import AddressSelect from "@/components/AddressSelect";
	import axios from "axios";
	import XLSX from "xlsx"
	export default {
		name: "QrCode",
		components: {
			AddressSelect
		},
		data() {
			return {
				ruleForm: {
					// "beginTime": "",
					// "endTime": "",
					// "name": "",
					// "page": 1,
					// "paymentState": 0,
					// "phone": "",
					// "size": 10,
					"beginTime": "",
					"bluetoothName": "",
					"endTime": "",
					"page": 1,
					"serialNumber": "",
					"size": 10
				},
				xxUrl: global.posturl.host,
				User: '',
				total: 0,
				tableData: [],
				daor: [],
				kongdizhi: '',
				add1: false,
				time: '',
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				// dialog
				dialogVisible: false,
				addRuleForm: {
					serialNumber: '',
					bluetoothName: '',
					// address: '',
					// installationTime: '',
					// mac: '', // 添加 3.26
					// name: '',
					deviceId: '', // 4.2
					deviceIosId: '',
					serviceId: '0000FFF0-0000-1000-8000-00805F9B34FB',
					characteristicId: '0000FFF3-0000-1000-8000-00805F9B34FB'
				},
				date: '',
				dialogbtn: 1,
				rules: {

					serialNumber: [{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}],
					// deviceId: [{
					// 	required: true,
					// 	message: '请输入设备MAC地址',
					// 	trigger: 'blur'
					// }],
					// deviceIosId: [{
					// 	required: true,
					// 	message: '请输入设备deviceId-IOS',
					// 	trigger: 'blur'
					// }],
					bluetoothName: [{
						required: true,
						message: '请输入设备蓝牙名称',
						trigger: 'blur'
					}]
					// ,
					// characteristicId: [{
					// 	required: true,
					// 	message: '请输入设备特征值',
					// 	trigger: 'blur'
					// }]
				}
			}
		},
		beforeCreate() {
			// this.User = sessionStorage.getItem('User')
			// console.log(sessionStorage.getItem("User"));
			// console.log(this.User,'User');
		},
		created() {
			this.User = sessionStorage.getItem("User")
			console.log(sessionStorage.getItem("User"));
			console.log(this.daor, 'daor');
			this.getInfo()

		},
		computed: {
			token() {
				return sessionStorage.getItem("token")
			}

		},
		methods: {

			// addall() {
			// 	this.add1 = true
			// },
			// addall1() {
			// 	this.add1 = false
			// 	this.daor = []
			// },
			// addall2() {
			// 	let _this = this
			// 	console.log(this.daor);
			// 	if (this.daor.length !== 0) {
			// 		_this.add1 = false
			// 		_this.daor = []
			// 		_this.getInfo()
			// 	} else {
			// 		_this.$message({
			// 			message: '没有文件',
			// 			type: 'warning'
			// 		});
			// 	}
			// },
			//上传文件
			// 导入表格
			// importExcel(param) {
			// 	// console.log(param);
			// 	let _this = this;
			// 	_this.file2Xce(param).then(item => {
			// 			if (item && item.length > 0) {
			// 				// xlsxJson就是解析出来的json数据,数据格式如下
			// 				// [{sheetName: sheet1, sheet: sheetData }]
			// 				if (item[0] && item[0].sheet && item[0].sheet.length) {
			// 					_this.daor = item[0].sheet //把数据塞到表格预览
			// 					console.log(_this.daor, '数据');
			// 					this.$message({
			// 						message: '添加成功',
			// 						type: 'success'
			// 					});
			// 				}
			// 			}
			// 		})
			// 		.catch(error => {
			// 			loading.close();
			// 		});
			// },
			// file2Xce(file) {
			// 	return new Promise(function(resolve, reject) {
			// 		const reader = new FileReader();
			// 		reader.onload = function(e) {
			// 			const data = e.target.result;
			// 			this.wb = XLSX.read(data, {
			// 				type: "binary"
			// 			});
			// 			const result = [];
			// 			this.wb.SheetNames.forEach(sheetName => {
			// 				result.push({
			// 					sheetName: sheetName,
			// 					sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
			// 				});
			// 			});
			// 			/* console.log("解析")
			// 			console.log(result) */
			// 			resolve(result);
			// 		};
			// 		reader.readAsBinaryString(file.raw);
			// 	});
			// },
			onsuccess(file) {
				console.log(file, '上传成功');
				if (file.success) {
					this.$message({
						message: '添加成功',
						type: 'success'
					});
				} else {
					this.$message({
						message: '重复添加请检查表格' + file.message,
						type: 'warning'
					});
				}

				this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
				// this.handleInitTableData();  //初始化表格数据
				this.getInfo();
			},
			onerror(file) {
				console.log(file, '失败');
			},
			submitUpload() {
				this.$refs.upload.submit();
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			H1() {
				console.log(this.User);
			},
			getInfo() {
				this.$ajax('qrCode', 'post', this.ruleForm).then(res => {
					console.log(res)
					console.log('搜索');
					this.tableData = res.data.records
					this.total = res.data.total
				})
			},
			changeTime(time) {
				console.log(time)
				if (time) {
					this.ruleForm.beginTime = time[0]
					this.ruleForm.endTime = time[1]
				} else {
					this.ruleForm.beginTime = ''
					this.ruleForm.endTime = ''
				}
				this.getInfo()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.ruleForm.size = val
				this.getInfo()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.ruleForm.page = val
				this.getInfo()
			},
			// 添加新设备 清空上次填写数据
			addDevice() {
				this.dialogVisible = true
				this.dialogbtn = 1
				this.addRuleForm.installationTime = ''
				this.addRuleForm.name = ''
				this.addRuleForm.serialNumber = ''
				this.addRuleForm.address = ''
				this.addRuleForm.mac = ''
				this.addRuleForm.deviceId = '' // 4.2
				this.addRuleForm.deviceIosId = ''
				this.addRuleForm.bluetoothName = ''
				// this.addRuleForm.serviceId = ''
				// this.addRuleForm.characteristicId = ''
			},
			changeTime2(time) {
				console.log(this.ruleForm.installationTime)
			},
			// 自组键穿的值
			allAddr(val) {
				console.log(val)
				this.addRuleForm.address = val
			},
			rrFormClear() {
				console.log('清空')
				this.addRuleForm.serialNumber = ''
				this.addRuleForm.address = ''
				this.addRuleForm.installationTime = ''
				this.addRuleForm.name = ''
				this.addRuleForm.mac = ''
				this.addRuleForm.bluetoothName = '',
					this.addRuleForm.deviceId = '' // 4.2
				this.addRuleForm.deviceIosId = ''
				// this.addRuleForm.serviceId = ''
				// this.addRuleForm.characteristicId = '' //

				this.dialogVisible = false
			},
			rrFormSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$ajax('qrAdd', 'post', this.addRuleForm).then(res => {
							console.log(res)
							if (res.code == 2000) {
								this.$message({
									message: '添加二维码成功',
									type: 'success'
								});
								this.dialogVisible = false
								this.getInfo()
							} else {
								console.log('error submit!!');
								return false;
							}
						});
					}
				})
			},
			edit(row) {
				console.log(row)
				this.dialogVisible = true
				this.addRuleForm.installationTime = row.installationTime
				this.addRuleForm.serialNumber = row.serialNumber
				this.addRuleForm.bluetoothName = row.bluetoothName
				this.addRuleForm.address = row.address
				this.addRuleForm.name = row.name
				this.addRuleForm.id = row.id
				this.addRuleForm.mac = row.mac
				this.addRuleForm.deviceId = row.deviceId // 4.2
				this.addRuleForm.deviceIosId = row.deviceIosId
				this.addRuleForm.serviceId = row.serviceId
				this.addRuleForm.characteristicId = row.characteristicId //
				this.dialogbtn = 2
			},
			rrFormEdit() {
				this.$ajax('qrUpdate', 'post', this.addRuleForm).then(res => {
					console.log(res)
					if (res.code == 2000) {
						this.$message({
							message: '修改成功', // 3.26 改动
							type: 'success'
						});
						this.dialogVisible = false
						this.getInfo()
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					}

				})
			},
			// 删除
			del(row) {
				console.log(row)
				this.$confirm('此操作将永久删除该文件是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axios.delete(global.posturl.host + '/test/delete/' + row.id, {
						headers: {
							'token': sessionStorage.getItem('token')
						},
					}).then(res => {
						console.log(res)
						this.getInfo()
						this.$message({
							type: 'success',
							message: '删除成功'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
		}
	}
</script>

<style scoped lang="scss">
	/deep/ .table-expand {
		label {
			width: 180px;
			color: #99a9bf;
		}

	}

	.table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}

	.rrForm {

		//height: 340px;
		.el-form-item {
			height: 60px;
		}
	}
</style>
