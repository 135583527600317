<template>
  <div class="main">
    <div class="sel">
      <el-form-item>
        <el-select v-model="proviceCode" popper-class="tab-select" placeholder="请选择省" @change="proviceChange">
          <el-option
              v-for="(item, index) in provice"
              :key="index"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="cityCode" popper-class="tab-select" placeholder="请选择市" @change="cityChange">
          <el-option
              v-for="(item, index) in city"
              :key="index"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="areaCode" popper-class="tab-select" placeholder="请选择市" @change="areaChange">
          <el-option
              v-for="(item, index) in area"
              :key="index"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="inp">
        <el-input :disabled="areaL == ''" v-model="detailAddr" placeholder="请输入详细地址"></el-input>
    </div>
  </div>
</template>

<script>
import address from '../json/allAddress.json'
export default {
  name: 'AddressSelect',
  props: {
    block: {
      type: Boolean,
      default: false
    },
    value: {
      required: false
    }
  },
  data() {
    return {
      provice: address,
      city: [],
      area: [],
      village: [],
      proviceCode: '',
      cityCode: '',
      areaCode: '',
      proviceL: '',
      cityL: '',
      areaL: '',
      villageCode: '',
      detailAddr: '',
      isOpen: {
        provice: false,
        city: false,
        area: false,
        village: false
      }
    }
  },
  computed: {
    allAddr() {
      return this.proviceL + this.cityL + this.areaL + this.detailAddr
    }
  },
  watch: {
    value (val) {
      if (val.code !== this.villageCode) {
        this.init()
      }
    },
    allAddr() {
      // console.log('Bianl')
      this.$emit('allAddr', this.allAddr)
    },
  },
  created() {

  },
  mounted () {

  },
  methods: {
    proviceChange(val) {
      console.log(val)
      //var ss = this.provice.findIndex(item => item.value == val)
      this.city = this.provice[val].children
      this.proviceL = this.provice[val].label
      this.cityCode = ''
      this.areaCode = ''
      this.cityL = ''
      this.areaL = ''
    },
    cityChange(val) {
      console.log(val)
      //var ss = this.provice.findIndex(item => item.value == val)
      this.area = this.city[val].children
      this.cityL = this.city[val].label
      this.areaCode = ''
    },
    areaChange(val) {
      console.log(val)
      this.areaL = this.area[val].label
      //var ss = this.provice.findIndex(item => item.value == val)
    },
  },
}
</script>
<style scoped lang="scss">
.main {
  //width: 400px;
  //background-color: red;
  .sel {
    display: flex;
    .el-select {
      margin-right: 10px;
    }
  }
  .inp {
    margin-top: 38px;
    .el-input {
      width: 710px;
    }
  }
}
</style>
